@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 85.7% 97.3%;

        --ring: 217.2 32.6% 17.5%;
    }
}

@layer base {
    * body {
        @apply bg-background text-foreground;
        font-display: swap;
    }

    body::-webkit-scrollbar-thumb {
        /* background-color: #0eb48d; */
        background-color: #3756a0;
        border-radius: 8px;
    }
    body::-webkit-scrollbar-thumb:hover {
        /* background: #0eb48d; */
        background: #3756a0;
    }
    body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px transparent;
        border-radius: 8px;
        background-color: transparent;
    }
    body::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }
    body::-webkit-scrollbar-thumb {
        /* background-color: #18aaca; */
        background-color: #3756a0;
        border: 1px solid transparent;
        border-radius: 30px;
    }

    /* scroll site css */

    *::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 8px;
    }
    *::-webkit-scrollbar-thumb:hover {
        background: #525252;
    }
    *::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px transparent;
        border-radius: 8px;
        background-color: transparent;
    }
    *::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }
    *::-webkit-scrollbar-thumb {
        background-color: #525252;
        border: 1px solid transparent;
        border-radius: 30px;
    }
    /* scroll site css */

    /* custom font css  */

    .custom-dots li.slick-active button:before,
    .custom-dots li button:before {
        color: white !important;
    }

    .list-lower-latin {
        list-style-type: lower-latin;
    }
    .coe-flex {
        display: flex !important;
    }

    .custom-container {
        position: relative;
        box-sizing: border-box;
        width: calc(100% - 40px);
        margin-left: auto;
        margin-right: auto;
        max-width: 1170px;
    }
    .carousal-container {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }
    /* Chatbot custom CSS */
    #kenytChatBubble.style1 img,
    #kenytChatBubble.style3 img {
        max-height: 100% !important;
        max-width: 100% !important;
        margin: auto 0 auto auto !important;
    }
    #messageWrapperBox {
        display: none !important;
    }
    /* details content css */
    .details_section p,
    .details_section li,
    .details_section p span,
    .details_section li span,
    .details_section em,
    .details_section strong,
    .details_section {
        font-size: 18px;
        line-height: 1.3 !important;
        word-spacing: 0px !important;
        letter-spacing: 0px !important;
    }
    @media screen and (max-width: 767px) {
        .custom-container {
            width: calc(100% - 30px);
            max-width: 540px;
            margin: auto;
        }
    }
    @media only screen and (min-device-width: 1200px) and (max-device-width: 1350px) {
        .custom-container {
            width: calc(100% - 180px);
        }
        .header-menu-bar {
            width: 1100px !important;
        }
        .menu-bookbutton button {
            padding-inline: 5px;
            width: auto;
        }
        .menu-bookbutton {
            padding-top: 4px !important;
        }
    }
    @media only screen and (min-device-width: 1025px) and (max-device-width: 1199px) {
        .custom-container {
            width: calc(100% - 180px);
        }
        .header-menu-bar {
            width: 1100px !important;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .custom-container {
            width: calc(100% - 60px);
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        .custom-container {
            width: calc(100% - 6em);
        }
    }
    p.header1,
    .header1 {
        font-size: 46px;
        line-height: 1.2;
        font-weight: 600;
        text-transform: capitalize;
    }
    .p-text {
        font-size: 18px;
        line-height: 1.3;
        font-weight: 400;
    }
    .header-sub {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 600;
        text-transform: capitalize;
    }
    /* custom button */
    .blue-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 45px;
        padding-inline: 20px;
        background-color: #3756a0;
        color: #fff;
        border: 1px solid #fff;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400px;
    }
    .blue-button:hover {
        background-color: #fff;
        color: #3756a0;
        border: 1px solid #3756a0;
        transition: all 0.5s ease-in-out;
    }
    .white-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 45px;
        padding-inline: 20px;
        background-color: #3756a0;
        color: #fff;
        border: 1px solid #3756a0;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400px;
    }
    .white-button:hover {
        background-color: #1f315b;
        color: #fff;
        border: 1px solid #fff;
        transition: all 0.5s ease-in-out;
    }

    /* custom testimonial css for dot images */
    .slick-dots.custom-imagedot {
        width: 100%;
        overflow-x: hidden;
        height: 60px;
        bottom: -70px;
        text-align: left;
        display: flex !important;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .slick-dots.custom-imagedot li {
        width: auto;
        max-height: 55px;
    }
    .slick-dots.custom-imagedot li img {
        filter: brightness(0.5);
        width: 100%;
        height: 52px;
        max-height: 52px;
        object-fit: contain;
        object-position: center;
    }
    .slick-dots.custom-imagedot li.slick-active img {
        filter: none;
    }
    .minus-margin {
        margin-top: -26px;
        margin-bottom: 30px;
    }
    /* custom testimonial css for dot images */
    @media only screen and (min-device-width: 1025px) and (max-device-width: 1199px) {
    }
    @media only screen and (min-device-width: 993px) and (max-device-width: 1024px) {
        p.header1,
        .header1 {
            font-size: 32px;
        }
        .p-text {
            font-size: 16px;
        }
    }
    @media only screen and (min-device-width: 769px) and (max-device-width: 992px) {
        p.header1,
        .header1 {
            font-size: 32px;
        }
        .p-text {
            font-size: 18px;
        }
        .header-sub {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 1024px) {
        .header-sub {
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 768px) {
        p.header1,
        .header1 {
            font-size: 32px;
        }
        .p-text {
            font-size: 16px;
        }
        .header-sub {
            font-size: 18px;
        }
        .mobile-arrow {
            height: 27px;
            width: 27px;
            background: #ffffff;
        }
    }
    @media only screen and (min-width: 1024px) {
        .homecarousel {
            height: 600px;
            width: 696px;
        }
        .hometextcarousel {
            width: 458px !important;
            height: 600px !important;
        }
    }
    @media only screen and (max-width: 550px) {
        p.header1,
        .header1 {
            font-size: 30px;
        }
        .p-text {
            font-size: 14px;
        }
        .header-sub {
            font-size: 18px;
        }
        .slick-dots {
            position: relative !important;
            bottom: 0 !important;
        }
        /* details content css */
        .details_section p,
        .details_section li,
        .details_section p span,
        .details_section li span,
        .details_section em,
        .details_section strong,
        .details_section {
            font-size: 16px !important;
        }
        .details_section ul {
            padding-left: 14px;
        }
    }
}

@keyframes zoomOutAndDisappear {
    0% {
        transform: scale(1.75);
    }
    100% {
        transform: scale(0);
    }
}

.zoomOutAndDisappear {
    animation: zoomOutAndDisappear 0.7s ease-in-out 0s forwards;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes zoomInAndAppear {
    0% {
        transform: scale(0);
        width: 0;
    }
    100% {
        transform: scale(1);
        width: 128px;
    }
}

.zoomInAndAppear {
    animation: zoomInAndAppear 0.7s ease-in-out 0.3s forwards;
    will-change: transform, width;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes typing {
    0% {
        /* transform: scale(0); */
    }
    100% {
        /* transform: scale(1); */
        width: 400px;
    }
}

.typing {
    animation: typing 0.5s ease-in-out 1.5s forwards;
    will-change: transform, width;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes showTag {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
        width: 520px;
        height: 66px;
    }
}

.showTag {
    animation: showTag 0.5s ease-in-out 2s forwards;
    will-change: transform, width, height, opacity;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

@keyframes fadeAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fadeAnimation {
    animation: fadeAnimation 0.3s ease-in-out 2.7s forwards;
    will-change: opacity;
    backface-visibility: hidden;
}

@keyframes showDummyDiv {
    0% {
        width: 0;
    }
    100% {
        width: 520px;
    }
}

.showDummyDiv {
    animation: showDummyDiv 0.5s ease-in-out 1.5s forwards;
    will-change: width;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}

/* Filter Radio button */

.filter-radio label {
    display: block;
    border-radius: 5px;
    padding: 10px 0px 10px 25px;
    margin-bottom: 5px;
    cursor: pointer;
}
.filter-radio label:after,
.filter-radio label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 6px;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 3px;
}
.filter-radio label:before {
    background: transparent;
    z-index: 2;
    border: 1px solid transparent;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
    background-image: url(../../public/images/call-logo.svg);
}
.filter-radio input[type="radio"] {
    display: none;
    position: absolute;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.filter-radio input[type="radio"]:checked + label {
    -webkit-animation-name: blink;
    animation-name: blink;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    border-color: hsl(107, 50%, 46%) !important;
}
.filter-radio input[type="radio"]:checked + label:after {
    background: none;
}
.filter-radio input[type="radio"]:checked + label:before {
    width: 20px;
    height: 20px;
    border-color: hsl(107, 50%, 46%) !important;
}
/* Header fonts */
.header-font-color {
    color: #525252;
}
.header-bg-color {
    background: #525252;
}
.header-border-color {
    border-color: #525252;
}
.onscroll-header-border-color {
    border-color: #525252;
}
.custom-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/home-appointment-images/chevron-down.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 16px 16px;
}
#kenytChatBubble.style1 img,
#kenytChatBubble.style3 img {
    max-height: 50px !important;
    max-width: unset !important;
}

/* Chatbot custom CSS starts */

#kenytChatBubble.style1 #kenytBubbleContainer {
    bottom: 80px !important;
}
@media screen and (max-width: 360px) and (min-width: 320px) {
    #kenytChatBubble.style1 {
        bottom: 52px !important;
    }
}
@media screen and (min-width: 361px) and (max-width: 425px) {
    #kenytChatBubble.style1 {
        bottom: 105px !important;
    }
}

/* Chatbot custom CSS ends */
